<template>
  <div v-if="hasPerm('documents.view_taxyearlycertificaterule')" style="display: inline-block">
    <loading-gif :short="true" :loading-name="loadingName"></loading-gif>
    <send-email-modal
      :name="id"
      :title="confirmSendingTitle"
      :text="confirmSendingText"
      :init-subject="subject"
      :init-body="body"
      :init-from-email="fromEmail"
      :choices="entitiesChoices"
      @confirmed="onConfirmSending($event)"
      @choices-changed="onChoicesChanged($event)"
    ></send-email-modal>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import SendEmailModal from '@/components/Modals/SendEmailModal.vue'
import store from '@/store'
import { existsIn } from '@/utils/arrays'

export default {
  name: 'tax-certificates-sender',
  components: {
    LoadingGif,
    SendEmailModal,
  },
  mixins: [
    BackendMixin
  ],
  props: {
    id: String,
    entities: Array,
    year: Number,
    canSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingName: 'tax-certificates-sender',
      selectedChoices: [],
      subject: '',
      body: '',
    }
  },
  computed: {
    emails() {
      if (this.entity) {
        return this.entity.getParentsContactIndividuals().map(
          elt => elt.email
        ).filter(elt => elt !== '')
      }
      return []
    },
    emailSubject() {
      return 'Attestation fiscale de frais de garde'
    },
    fromEmail() {
      return store.getters.userEmail
    },
    confirmSendingTitle() {
      const entities = this.selectedEntities
      if (entities.length > 1) {
        return 'Envoi d\'une attestation de frais de garde'
      } else {
        return 'Envoi de ' + entities.length + 'attestations de frais de garde'
      }
    },
    confirmSendingText() {
      let text = ''
      const entities = this.selectedEntities
      if (entities.length === 1) {
        const entity = entities[0]
        text = 'Souhaitez-vous envoyer l\'attestation de frais de garde de la famille ' + entity.name
      } else if (entities.length > 1) {
        text = 'Souhaitez-vous envoyer les attestations de frais de garde aux ' + entities.length
        text += ' familles suivantes: '
        text += entities.map(itm => itm.name).join(', ')
      }
      if (text) {
        text += ' depuis le serveur millibase.net?\nL\'envoi ne sera pas immédiat et mis en file d\'attente'
      }
      return text
    },
    entitiesChoices() {
      if (this.canSelect) {
        return this.entities.map(elt => {
          return {
            id: elt.id,
            name: elt.name,
          }
        })
      } else {
        return []
      }
    },
    selectedEntities() {
      if (this.canSelect) {
        const selectedIds = this.selectedChoices.map(elt => elt.id)
        return this.entities.filter(itm => existsIn([itm.id], selectedIds))
      } else {
        return this.entities
      }
    },
  },
  mounted() {
    this.init()
  },
  watch: {
    year() {
      this.init()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess', 'addWarning']),
    ...mapMutations(['startLoading', 'endLoading']),
    async init() {
      let url = '/documents/api/send-tax-yearly-certificates/' + this.year + '/' + 0 + '/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.subject = resp.data.subject
        this.body = resp.data.body
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async onConfirmSending(event) {
      this.startLoading(this.loadingName)
      const entities = this.selectedEntities
      const entityIds = entities.map(elt => elt.id)
      let url = '/documents/api/tax-certificate-batch-emails/'
      const data = {
        entities: entityIds,
        subject: event.subject,
        body: event.body,
        sender: event.fromEmail,
        year: this.year,
      }
      const backendApi = new BackendApi('post', url)
      try {
        const resp = await backendApi.callApi(data)
        const sentCount = resp.data.sent.length
        const notSentCount = resp.data['not_sent'].length
        if (sentCount) {
          let text = ''
          if (sentCount > 1) {
            text += '' + sentCount + ' emails ont été envoyés'
          } else {
            text += 'Un email a été envoyé'
          }
          await this.addSuccess(text)
        }
        if (notSentCount) {
          let text = 'Adresse email manquante ou pas de contact par email: '
          if (notSentCount > 1) {
            text += '' + notSentCount + ' emails n\'ont pas pu être envoyés'
          } else {
            text += 'Un email n\'a pas pu être envoyé'
          }
          await this.addWarning(text)
        }
        this.$emit('done', { entities: this.entities, })
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    onChoicesChanged(event) {
      this.selectedChoices = event.choices
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>

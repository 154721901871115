<template>
  <div v-if="hasPerm('documents.view_taxyearlycertificaterule')">
    <page-header title="Attestation fiscale de frais de garde" icon="fas fa-money-bill-alt"></page-header>
    <div v-if="schoolYears.length === 0">
      <b-row>
        <b-col>
          <div class="warning3-text">
            Les attestations fiscales ne sont possibles que pour les années écoulées et non archivées.
            Aucune année ne correspond à ce critère.
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-row>
        <b-col cols="1">
          <b-form-group
            id="year-group"
            label="Année"
            label-for="year"
          >
            <b-select v-model="selectedSchoolYear" id="year" @change="yearChanged()">
              <b-select-option v-for="schoolYear of schoolYears" :key="schoolYear.id" :value="schoolYear">
                {{ schoolYear.startYear }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            label="Filtre"
            label-for="nameFilter"
            description="Entrez le nom d'une famille"
          >
            <b-form-input
              type="text"
              id="nameFilter"
              v-model="nameFilter"
              size="sm"
              :disabled="elements.length === 0"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            label-for="price"
            label="Prix de repas"
            description="Seuls les frais de garde peuvent donner droit à un crédit d'impôt. Le prix du repas est déduit"
          >
            <decimal-input
              v-model="lunchPrice"
              id="price"
              @change="onLunchPriceChanged()"
              :disabled="taxRule !== null"
              v-if="!familyLevelLunchPrice"
            >
            </decimal-input>
            <div v-if="familyLevelLunchPrice">
              <div v-for="elt of lunchPrices" :key="elt.id">
                <b-row>
                  <b-col>
                    <number-input
                      v-model="elt.familyLevel"
                      :id="'level' + elt.id"
                      disabled
                    >
                    </number-input>
                  </b-col>
                  <b-col>
                    <decimal-input
                      v-model="elt.lunchPrice"
                      :id="'lunch' + elt.id"
                      disabled
                    >
                    </decimal-input>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            id="age-rule-group"
            label="Age"
            label-for="age-rule"
          >
            <b-select
              v-model="selectedAgeRule"
              id="age-rule"
              :disabled="taxRule !== null || ageRuleChoices.length <= 1"
              @change="onRuleChanged()"
            >
              <b-select-option v-for="ageRule of ageRuleChoices" :key="ageRule.id" :value="ageRule.id">
                {{ ageRule.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            label="Affichage"
            label-for="childByChild"
            description="dans le document généré"
          >
            <b-form-checkbox
              id="childByChild"
              v-model="childByChild"
              :disabled="taxRule !== null"
            >
              Détail par enfant
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols=2 class="text-right" style="padding-top: 30px">
          <b-form-group
            description="La validation figera les paramètres et permettra l'envoi par email"
            v-if="isActive && taxRule === null && hasPerm('documents.add_taxyearlycertificaterule')"
          >
            <a
              class="btn btn-primary"
              :class="{ disabled: isLoading(loadingName) }"
              href
              @click.prevent="saveTaxRule(selectedSchoolYear.startYear)"
            >
              <i class="fa fa-check"></i> Valider
            </a>
          </b-form-group>
          <div v-if="isActive && taxRule !== null && hasPerm('documents.add_taxyearlycertificaterule')">
            <a
              class="btn btn-danger"
              :class="{ disabled: isLoading(loadingName) }"
              href
              @click.prevent="clearTaxRule()"
            >
              <i class="fa fa-warning"></i> Modifier les règles
            </a>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col>
        <b-form-group
          label="Paiements"
          label-for="paid"
        >
          <b-form-checkbox
            id="paid"
            v-model="paid"
            :disabled="taxRule !== null"
            @change="onRuleChanged()"
          >
            Seulement les inscriptions payées dans l'année
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="-"
          label-for="strict"
          v-if="paid"
        >
          <b-form-checkbox
            id="strictPayments"
            v-model="strictPayments"
            :disabled="taxRule !== null"
            v-if="ignoredModes"
            @change="onRuleChanged()"
          >
            Ne pas prendre en compte les paiements {{ ignoredModes }}
          </b-form-checkbox>
          <b-form-checkbox
            id="strictPayments"
            disabled
            v-else
          >
            Tous les modes de paiements sont pris en compte
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label-for="sendingStatus" label="" v-if="taxRule !== null">
          <b-select id="sendingStatus" v-model="sendingStatus">
            <b-select-option :value="0">Tous</b-select-option>
            <b-select-option :value="1">Non envoyé</b-select-option>
            <b-select-option :value="2">Envoyée</b-select-option>
          </b-select>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <div v-if="taxRule !== null && hasPerm('documents.view_taxyearlycertificatesending')">
          <a
            class="btn btn-primary"
            href
            @click.prevent="sendFromServer()"
          >
            <i class="fa fa-plane-departure"></i> Envoi par email depuis millibase.net
          </a>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="settingsWarning" class="settings-info" :class="{ 'warning4-text': !settingsOK }">
          <span v-if="settingsOK">
            <i class="fa fa-check-circle"></i>
          </span>
          <span v-else>
            <i class="fa fa-warning"></i> Attention! Cette règle n'est pas valide.<br />
          </span>
          {{ settingsWarning }}
        </div>
      </b-col>
      <b-col cols="3" v-if="selectedSchoolYear && kindChoices.length">
        <b-form-group id="kind-group" label-for="kind">
          <b-select v-model="kind" id="kind" @change="onKindChanged()" :disabled="isLoading(loadingName)">
            <b-select-option v-for="kind of kindChoices" :key="kind.id" :value="kind.id">
              {{ kind.name }}
            </b-select-option>
          </b-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div v-if="!isLoading(loadingName)">
          <table class="table table-bordered table-full">
            <tr v-for="elt of filteredElements" :key="elt.entity.id">
              <th rowspan="elt.children.length">
                <a href @click.prevent="showEntitySidebar(elt.entity)">
                  <span class="family-numbers">{{ elt.entity.id }}</span>
                  {{ elt.entity.name }}
                </a>
              </th>
              <th class="text-right">
                {{ getTotal(elt) | currency }}
              </th>
              <td>
                <table class="table table-striped">
                  <tr v-for="childElt of elt.children" :key="childElt.id">
                    <td style="width: 30%">
                      {{ childElt.child.lastAndFirstName() }}
                    </td>
                    <td style="width: 15%" class="text-center">
                      {{ childElt.child.birthDate | dateToString }}
                    </td>
                    <td class="text-right" style="width: 15%">
                      {{ childElt.amount | currency }}
                    </td>
                    <td style="width: 33%">
                      <span v-if="childElt.lunchCount">
                        Déduction pour
                        <counter-label :counter="childElt.lunchCount" label="repas" label-n="repas"></counter-label>
                        {{ childElt.lunchPrice | currency }}
                      </span>
                    </td>
                  </tr>
                </table>
              </td>
              <td class="text-right">
                <span v-if="taxRule !== null && hasPerm('documents.view_taxyearlycertificatesending')">
                  <a
                    class="btn"
                    :class="elt.sending ? 'btn-secondary' : 'btn-primary'"
                    href
                    @click.prevent="sendByEmail(elt)"
                  >
                    <i class="fa fa-paper-plane"></i> Envoi par email
                  </a>
                  <div class="small-text" v-if=elt.sending>
                    Envoyé le {{ elt.sending.sentOn | dateToString }} par {{ elt.sending.sentBy }}
                  </div>
                  <div>
                    <a href @click.prevent="downloadDocument(elt)" class="download-link">
                      <i class="fa fa-download"></i> Télécharger
                    </a>
                  </div>
                </span>
              </td>
            </tr>
          </table>
        </div>
      </b-col>
    </b-row>
    <confirm-modal
      name="clear-tax-rule"
      title="Réinitialiser les règles"
      text="Vous pourrez ensuite modifier les règles pour l'attestation fiscale"
      @confirmed="onTaxRuleClear()"
    ></confirm-modal>
    <tax-certificates-sender
      id="tax-certificates-sender"
      can-select
      :year="selectedSchoolYear.startYear"
      v-if="selectedSchoolYear && taxRule"
      :entities="entities"
      @done="loadCertificates()"
    ></tax-certificates-sender>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import { makeChoice } from '@/types/base'
import { makeTaxYearlyCertificateRule, TaxYearlyCertificateAgeRule, makeSending } from '@/types/document'
import { makeEntity, makeIndividual } from '@/types/people'
import { makeSchoolYear } from '@/types/schools'
import { BackendApi } from '@/utils/http'
import { makePaymentMode } from '@/types/payments'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import { sum } from '@/utils/math'
import TaxCertificatesSender from '@/components/Tools/TaxCertificatesSender.vue'
import NumberInput from '@/components/Controls/NumberInput.vue'

export default {
  name: 'tools-yearly-tax-certificates',
  components: {
    NumberInput,
    TaxCertificatesSender,
    ConfirmModal,
    DecimalInput,
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  mixins: [BackendMixin],
  data() {
    return {
      schoolYears: [],
      elements: [],
      loadingName: 'tools-yearly-tax-certificates',
      selectedSchoolYear: null,
      lunchPrice: 0,
      childByChild: false,
      taxRule: null,
      selectedAgeRule: 0,
      nameFilter: '',
      paid: false,
      strictPayments: false,
      paymentModes: [],
      kind: 0,
      sendingStatus: 0,
    }
  },
  computed: {
    ageRuleChoices() {
      const choices = []
      if (this.selectedSchoolYear) {
        let year = this.selectedSchoolYear.startYear
        choices.push(
          makeChoice({
            id: TaxYearlyCertificateAgeRule.LessThanSevenOnDec31,
            name: 'Moins de 6 ans au 1er janvier ' + year,
          })
        )
        if (year < 2024) {
          choices.push(
            makeChoice({
              id: TaxYearlyCertificateAgeRule.LessThanSevenOnSeance,
              name: '7 ans et moins au jour de présence',
            })
          )
          choices.push(
            makeChoice({
              id: TaxYearlyCertificateAgeRule.SevenOrLessOnDec31,
              name: 'Moins de 7 ans au 1er janvier ' + year,
            })
          )
        }
      }
      return choices
    },
    hasNursery() {
      return store.getters.config.nursery
    },
    kindChoices() {
      if (this.hasNursery) {
        return [
          makeChoice({
            id: 0,
            name: 'Tous',
          }),
          makeChoice({
            id: 1,
            name: 'Accueil de loisirs',
          }),
          makeChoice({
            id: 2,
            name: 'Crèche',
          })
        ]
      } else {
        return []
      }
    },
    isActive() {
      return !!(this.selectedSchoolYear && this.selectedAgeRule)
    },
    settingsWarning() {
      if (this.selectedSchoolYear) {
        let revenuesYear = this.selectedSchoolYear.startYear
        let taxYear = revenuesYear + 1
        let limBirthYear = revenuesYear - 6
        let text = 'L\'enfant doit avoir moins de 6 ans le 1er janvier de l\'année d\'imposition. '
        text += ' Pour la déclaration ' + taxYear + ' des revenus ' + revenuesYear +
          ', il doit être né en ' + limBirthYear + ' ou après'
        return text
      }
      return ''
    },
    settingsOK() {
      return (this.selectedAgeRule === 3)
    },
    separator() {
      return window.localStorage.getItem('emailSeparator') || ','
    },
    ignoredModes() {
      return this.paymentModes.filter(elt => elt.notStrict).map(elt => elt.name).join(', ')
    },
    filteredElements() {
      let elements = this.elements
      if (this.sendingStatus === 1) {
        elements = elements.filter(
          elt => !elt.sending
        )
      }
      if (this.sendingStatus === 2) {
        elements = elements.filter(
          elt => elt.sending
        )
      }
      if (this.nameFilter) {
        const nameFilter = this.nameFilter.toLowerCase()
        elements = elements.filter(
          elt => elt.entity.name.toLowerCase().indexOf(nameFilter) >= 0
        )
      }
      return elements
    },
    entities() {
      return this.filteredElements.map(elt => elt.entity)
    },
    familyLevelLunchPrice() {
      return (this.taxRule !== null) && (this.taxRule.familyLevelLunchPrice)
    },
    lunchPrices() {
      return (this.familyLevelLunchPrice) ? (this.taxRule.lunchPrices) : []
    },
  },
  mounted() {
    this.onLoaded()
  },
  watch: {
    ageRuleChoices() {
      if (this.ageRuleChoices.length === 1) {
        this.selectedAgeRule = this.ageRuleChoices[0].id
      } else {
        this.selectedAgeRule = null
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded() {
      await this.loadSchoolYears()
      await this.loadPaymentModes()
    },
    async loadPaymentModes() {
      try {
        let url = '/api/payment-modes/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.paymentModes = this.paymentModes.concat(resp.data.map(elt => makePaymentMode(elt)))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadSchoolYears() {
      this.startLoading(this.loadingName)
      this.schoolYears = []
      const thisYear = moment().toDate().getFullYear()
      let url = '/api/school-years/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(
          elt => makeSchoolYear(elt)
        ).filter(
          elt => elt.startYear < thisYear
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async yearChanged() {
      await this.loadTaxRule(this.selectedSchoolYear.startYear)
      await this.loadCertificates()
    },
    onLunchPriceChanged() {
      if (!this.taxRule) {
        this.loadCertificates()
      }
    },
    onKindChanged() {
      this.loadCertificates()
    },
    onRuleChanged() {
      if (!this.taxRule) {
        this.loadCertificates()
      }
    },
    async loadTaxRule(year) {
      this.startLoading(this.loadingName)
      let url = '/documents/api/tax-yearly-certificates-rule/' + year + '/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        if (resp.data.year) {
          this.taxRule = makeTaxYearlyCertificateRule(resp.data)
          this.lunchPrice = this.taxRule.lunchPrice
          this.selectedAgeRule = this.taxRule.ageRule
          this.childByChild = this.taxRule.childByChild
          this.paid = this.taxRule.paid
          this.strictPayments = this.taxRule.strictPayments
        } else {
          this.taxRule = null
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    clearTaxRule() {
      if (this.taxRule) {
        this.$bvModal.show('bv-confirm-modal:clear-tax-rule')
      }
    },
    async saveTaxRule(year) {
      this.startLoading(this.loadingName)
      let url = '/documents/api/tax-yearly-certificates-rule/' + year + '/'
      const backendApi = new BackendApi('post', url)
      try {
        const data = {
          year: this.selectedSchoolYear.startYear,
          lunch_price: +this.lunchPrice,
          age_rule: this.selectedAgeRule,
          child_by_child: this.childByChild,
          paid: this.paid,
          strict_payments: this.strictPayments,
          family_level_lunch_price: this.familyLevelLunchPrice,
          lunch_prices: this.lunchPrices.map(
            elt => {
              return {
                id: elt.id > 0 ? elt.id : 0,
                family_level: elt.familyLevel,
                lunch_price: elt.lunchPrice,
              }
            }
          ),
        }
        const resp = await backendApi.callApi(data)
        this.taxRule = makeTaxYearlyCertificateRule(resp.data)
        this.lunchPrice = this.taxRule.lunchPrice
        this.selectedAgeRule = this.taxRule.ageRule
        this.childByChild = this.taxRule.childByChild
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async sendByEmail(elt) {
      const year = this.selectedSchoolYear.startYear
      let url = '/documents/api/send-tax-yearly-certificates/' + year + '/' + elt.entity.id + '/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        elt.sending = makeSending(resp.data.sending)
        let subject = resp.data.subject
        let body = resp.data.body
        let emails = resp.data.emails
        if (emails.length === 0) {
          await this.addError('Aucun email n\'est défini pour cette famille')
        } else {
          let mailto = 'mailto:' + emails.join(this.separator)
          mailto += '?subject=' + subject
          mailto += '&body=' + body
          mailto = encodeURI(mailto)
          window.location.href = mailto
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async downloadDocument(elt) {
      const year = this.selectedSchoolYear.startYear
      let url = '/documents/api/get-tax-yearly-certificates/' + year + '/' + elt.entity.id + '/'
      url += '?kind=' + this.kind
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        window.open(resp.data['pdf_url'], '_blank')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadCertificates() {
      if (this.selectedSchoolYear && this.selectedAgeRule) {
        this.startLoading(this.loadingName)
        this.elements = []
        const data = {
          year: this.selectedSchoolYear.startYear,
          lunch_price: +this.lunchPrice,
          age_rule: this.selectedAgeRule,
          child_by_child: this.childByChild,
          paid: this.paid,
          strict_payments: this.strictPayments,
          kind: this.kind,
          family_level_lunch_price: this.familyLevelLunchPrice,
          lunch_prices: this.lunchPrices.map(
            elt => {
              return {
                id: elt.id > 0 ? elt.id : 0,
                family_level: elt.familyLevel,
                lunch_price: elt.lunchPrice,
              }
            }
          ),
        }
        let url = '/documents/api/tax-yearly-certificates/' + this.selectedSchoolYear.startYear + '/'
        const backendApi = new BackendApi('post', url)
        try {
          const resp = await backendApi.callApi(data)
          this.elements = resp.data.map(elt => {
            return {
              entity: makeEntity(elt.entity),
              sending: elt.sending ? makeSending(elt.sending) : null,
              children: elt.children.map(
                subElt => {
                  return {
                    child: makeIndividual(subElt.child),
                    amount: +subElt.amount,
                    lunchCount: +subElt.lunch_count,
                    lunchPrice: +subElt.lunch_price,
                  }
                }
              ),
            }
          })
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    getTotal(elt) {
      return sum(elt.children.map(itm => itm.amount))
    },
    onTaxRuleClear() {
      this.taxRule = null
      if (this.ageRuleChoices.length === 1) {
        this.selectedAgeRule = this.ageRuleChoices[0].id
      }
    },
    sendFromServer() {
      this.$bvModal.show('bv-send-email-modal:tax-certificates-sender')
    },
  },
}
</script>

<style scoped lang="less">
.table-full {
  width: 100%;
}
.warning3-text {
  padding: 5px;
  background: #f2f2a2;
  color: #222;
  font-weight: bold;
}
.download-link {
  font-size: 12px;
}
.settings-info {
  padding: 5px;
  font-weight: bold;
  margin-bottom: 10px;
  background: #cce5ff;
  color: #222;
}
.warning4-text {
  background: #ee93a0;
  color: #222;
}
</style>
